<template>
  <div class="property-analysis animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'PropertyAnalysiss'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2]
          }"
          :searchBarTitle="'Search...'"
          :getFunction="getAllMilestones"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "property-analysis",
  data() {
    return {
      routerButton: [
        {
          title: "Add PropertyAnalysis",
          class: "add",
          routerLink: "/property-analysis/add"
        }
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/property-analysis/edit"
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to delete this property analysis? You cannot undo after this."
            );

            if (c) {
              return this.removePropertyAnalysis(id);
            }
          },
          getData: true
        }
      ]
    };
  },
  methods: {
    async getAllMilestones(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch(
        "propertyAnalysis/getAllPropertyAnalysis",
        {
          keyword: keyword,
          currentPage: currentPage,
          limit: perPage,
          isLoading: isLoading
        }
      );

      return request;
    },
    async removeMilestone(id) {
      const request = await this.$store.dispatch(
        "propertyAnalysis/deletePropertyAnalysis",
        id
      );

      return request;
    }
  }
};
</script>

<style>
.property-analysis {
}
</style>
