import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllPropertyTypes({ commit }, payload) {
            try {
                let include;

                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-types`,
                    {
                        params: {
                            ...payload,
                            include: include,
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getPropertyType({ commit }, id) {
            try {
                let include = "country,state,city";

                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-types/${id}` +
                    `?include=${include}`,
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createPropertyTypes({ commit }, content) {
            try {
                const request = await axios.post(`${this._vm.$adminAPI}/property-types`, content);

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updatePropertyTypes({ commit }, { id, content }) {
            console.log(content);
            commit("setIsLoading", true, { root: true, });

            try {
                const request = await axios.put(`${this._vm.$adminAPI}/property-types/${id}`, content);

                commit("setIsLoading", false, { root: true, });
                return request.data;
            } catch (error) {
                commit("setIsLoading", false, { root: true, });
                throw error;
            }
        },
        async deletePropertyTypes({ commit }, id) {
            try {
                const request = await axios.delete(`${this._vm.$adminAPI}/property-types/${id}`);

                return request;
            } catch (error) {
                throw error;
            }
        },
    },
};
