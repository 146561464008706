import axios from "axios";
import firebase from "../../modules/firebase-config";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async login({ commit }, context) {
            commit("setIsLoading", true, { root: true });
            let messagingToken = null;

            if (Notification.permission == "granted") {
                const getMessagingToken = await firebase.messaging().getToken({
                    vapidKey:
                        "BENmdvPx8icY3-gWXO__TLyzOy2maOhZ-YzHBdpstLA3fbSFzFbJ70T_6V8M_eGaad5NeKcRrcQo95XxrKnfki0"
                });

                if (getMessagingToken) {
                    messagingToken = getMessagingToken;
                    // sendTokenToServer(getMessagingToken);
                    // updateUIForPushEnabled(getMessagingToken);
                } else {
                    // Show permission request.
                    console.log(
                        "No registration token available. Request permission to generate one."
                    );
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    // setTokenSentToServer(false);
                }
            }

            try {
                let req = await this._vm.$auth.login({
                    data: context.data,
                    staySignedIn: true
                });

                const userData = { ...req.data.user, fcmToken: messagingToken };
                this._vm.$auth.user(userData);

                commit("setIsLoading", false, { root: true });
                return true;
            } catch (err) {
                commit("setIsLoading", false, { root: true });
                throw err;
            }
        }
    }
};
