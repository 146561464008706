import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async uploadImage({ commit }, payload) {
      try {
        let response = await axios.post(
          `${this._vm.$commonAPI}/file-uploader/image`,
          payload
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    async uploadFile({ commit }, payload) {
      try {
        let response = await axios.post(
          `${this._vm.$commonAPI}/file-uploader/file`,
          payload
        );
        return response;
      } catch (error) {
        throw error;
      }
    }
  }
};
