import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        // ======================== Main CRUD  ========================
        async getAllDevelopers(
            { commit },
            payload = {
                limit: 50
            }
        ) {
            try {
                let include = "";

                let response = await axios.get(
                    `${this._vm.$adminAPI}/developers`,
                    {
                        params: {
                            ...payload,
                            include: include
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getDeveloper({ commit }, id) {
            let include = "";

            try {
                let response = await axios.get(
                    `${this._vm.$adminAPI}/developers/${id}?include=${include}`
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createDeveloper({ commit }, payload) {
            try {
                const response = await axios.post(
                    `${this._vm.$adminAPI}/developers`,
                    payload
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async updateDeveloper({ commit }, { id, payload }) {
            try {
                const request = await axios.put(
                    `${this._vm.$adminAPI}/developers/${id}`,
                    payload
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async deleteDeveloper({ commit }, id) {
            try {
                const response = await axios.delete(
                    `${this._vm.$adminAPI}/developers/${id}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        }
    }
};
