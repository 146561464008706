import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllPropertyTags({ commit }, payload = { limit: 200 }) {
            try {
                let include;

                let response = await axios.get(
                    `${this._vm.$commonAPI}/hashtags`,
                    {
                        params: {
                            ...payload,
                            include: include
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getPropertyTag({ commit }, id) {
            try {
                let include = "country,state,city";

                let response = await axios.get(
                    `${this._vm.$commonAPI}/hashtags/${id}` +
                        `?include=${include}`
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createPropertyTags({ commit }, content) {
            try {
                const request = await axios.post(
                    `${this._vm.$adminAPI}/hashtags`,
                    content
                );

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async deletePropertyTags({ commit }, id) {
            try {
                const request = await axios.delete(
                    `${this._vm.$adminAPI}/hashtags/${id}`
                );

                return request;
            } catch (error) {
                throw error;
            }
        }
    }
};
