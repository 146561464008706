import axios from "axios";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async getAllFacilities({ commit }, payload) {
            try {
                let include;

                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-facilities`,
                    {
                        params: {
                            ...payload,
                            include: include
                        }
                    }
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async getFacility({ commit }, id) {
            try {
                let include = "country,state,city";

                let response = await axios.get(
                    `${this._vm.$commonAPI}/property-facilities/${id}` +
                    `?include=${include}`,
                );

                return response.data;
            } catch (error) {
                throw error;
            }
        },
        async createFacilities({ commit }, content) {
            try {
                const request = await axios.post(`${this._vm.$adminAPI}/property-facilities`, content);

                return request.data;
            } catch (error) {
                throw error;
            }
        },
        async updateFacilities({ commit }, content) {
            commit("setIsLoading", true, { root: true, });

            try {
                const request = await axios.put(`${this._vm.$adminAPI}/property-facilities/${content.id}`, content.data);

                commit("setIsLoading", false, { root: true, });
                return request.data;
            } catch (error) {
                commit("setIsLoading", false, { root: true, });
                throw error;
            }
        },
        async deleteFacilities({ commit }, id) {
            try {
                const request = await axios.delete(`${this._vm.$adminAPI}/property-facilities/${id}`);

                return request;
            } catch (error) {
                throw error;
            }
        },
    },
};
