import axios from "axios";
import { AgencyModel } from "@/models/agency";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async getAllAgencies({ commit }, payload) {
      try {
        let include;

        let response = await axios.get(
          `${this._vm.$adminAPI}/agencies`,
          {
            params: {
              ...payload,
              include: include,
            }
          }
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAgency({ commit }, { id }) {
      try {
        let response = await axios.get(`${this._vm.$adminAPI}/agencies/${id}`);

        return AgencyModel.getAgencyDetailResponse(response.data);
      } catch (error) {
        throw error;
      }
    },
    async createAgency({ commit }, payload) {
      try {
        const response = await axios.post(
          `${this._vm.$adminAPI}/agencies`,
          AgencyModel.postAgencyDetailPayload(payload),
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateAgency({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$adminAPI}/agencies/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteAgency({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.delete(`/api/manage-agency/${content}`);

        commit("setIsLoading", false, { root: true });
        return true;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        return false;
      }
    },
    async getAgencyRoles({ commit }, id) {
      try {
        const response = await axios.get(
          `${this._vm.$adminAPI}/agencies/${id}/roles`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
