import { convertNullIfEmpty } from "@/utils/null-check";

export class AgencyModel {
    static getAgencyDetailResponse(e) {
        return {
            name: e.name || "",
            logo: e.logo || [],
            website: e.website || "",
            personInChargeName: e.personInChargeName || "",
            personInChargeContact: e.personInChargeContact || "",
        };
    }
    static postAgencyDetailPayload(e) {
        return {
            name: convertNullIfEmpty(e.name) || "",
            personInChargeName: convertNullIfEmpty(e.personInChargeName) || "",
            personInChargeContact: convertNullIfEmpty(e.personInChargeContact) || "",
            website: convertNullIfEmpty(e.website) || "",
            logo: convertNullIfEmpty(e.logo) || [],
        };
    }
}