<template>
  <div class="property-analysis-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2">
          <h4 class="mainTitle lineBottom">PropertyAnalysis Content</h4>
          <div class="main-contents">
            <div class="input-wrapper required mt-2 px-0">
              <label>Input:</label>
              <input type="text" required v-model="propertyAnalysis.input" />
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label>Date:</label>
              <div class="position-relative">
                <input type="date" v-model="propertyAnalysis.date" />
              </div>
            </div>

            <div class="input-wrapper required py-1 px-0">
              <label class="d-block mb-1">Select:</label>
              <fd-select
                :value="propertyAnalysis.select"
                @change="
                  (val) => {
                    propertyAnalysis.category = val;
                  }
                "
                :required="true"
              >
                <option :value="null">Choose an option</option>
                <option
                  v-for="(option, index) in options"
                  :key="`option${option.id}/${index}`"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </fd-select>
            </div>

            <div class="input-wrapper py-1 px-0">
              <label>Text Editor:</label>
              <trumbowyg
                v-model="propertyAnalysis.textEditor"
                :config="$trumbowyg"
              ></trumbowyg>
            </div>
          </div>
        </div>

        <div class="btn-wrapper d-flex justify-content-end px-0 mxn-0 sm-mxn-1">
          <button type="submit" class="btn add mx-1">Create</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "property-analysis-add",
  data() {
    return {
      propertyAnalysis: {
        input: "",
        date: "",
        select: "",
        textEditor: ""
      },
      options: [
        {
          id: 1,
          name: "Option 1"
        }
      ]
    };
  },
  methods: {
    async submitForm() {
      try {
        const request = await this.$store.dispatch(
          "propertyAnalysis/createPropertyAnalysis",
          this.propertyAnalysis
        );

        alert("PropertyAnalysis created successfully.");
        this.$router.push({ path: "/property-analysis" });
        return;
      } catch (error) {
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.property-analysis-add {
  form {
    @extend %formDesign;
  }
}
</style>
