import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jQuery from "jquery";
global.jQuery = jQuery;

// Vue Composition API
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

// VueMoment
import vuemoment from "vue-moment";
import moment from "moment";
Vue.use(vuemoment, {
    moment
});

// Dayjs
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(customParseFormat).extend(relativeTime);
Vue.prototype.$dayjs = dayjs;

// VueLodash
import lodashConfig from "./lodashConfig";
import VueLodash from "vue-lodash";
Vue.use(VueLodash, { lodash: lodashConfig });

// VueAuth
import auth from "@websanova/vue-auth";
import authConfig from "./auth";
Vue.use(auth, authConfig);

// vClickOutsite
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

// VueNotification
import Notifications from "vue-notification";
Vue.use(Notifications);

// VTooltip
import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
    defaultTrigger: window.innerWidth > 768 ? "hover" : "click"
});

// VueCoolLightBox
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
Vue.use(CoolLightBox);

import VueAwesomeSwiper from "vue-awesome-swiper";
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

import ConfirmModal from "@/components/GlobalComponents/ModalBox/ModalPlugin";
Vue.use(ConfirmModal);

import PortalVue from "portal-vue";

Vue.use(PortalVue);

// Global Base CSS
import "@/assets/GlobalStyling/static-css/base.css";
import "@/assets/GlobalStyling/static-css/material-colors/colors.css";
import "@/assets/GlobalStyling/style.scss";

// x5GMaps
import x5GMaps from "x5-gmaps";
Vue.use(x5GMaps, {
    key: process.env.VUE_APP_GOOGLE_MAP_API,
    libraries: [
        "visualization",
        "drawing",
        "geometry",
        "localContext",
        "places"
    ]
});

Vue.config.productionTip = false; // Disable console display "Running in development mode"

import "./utils";
import config from "./config";
Vue.prototype.$config = config;

// Axios
import "./axios";

// URLs
Vue.prototype.$apiURL = process.env.VUE_APP_API_URL;
Vue.prototype.$adminAPI = `${process.env.VUE_APP_API_URL}/api/admin`;
Vue.prototype.$agencyAPI = `${process.env.VUE_APP_API_URL}/api/agency`;
Vue.prototype.$commonAPI = `${process.env.VUE_APP_API_URL}/api/common`;
Vue.prototype.$imageAPI = `${process.env.VUE_APP_API_URL}/api/common/file-uploader/image`;
Vue.prototype.$googleApiKey = process.env.VUE_APP_GOOGLE_MAP_API;

new Vue({
    router,
    root: process.env.VUE_APP_API_URL,
    store,
    config,
    render: (h) => h(App)
}).$mount("#app");
