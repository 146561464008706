import Vue from "vue";
import VueRouter from "vue-router";

// ================================= Analysis ==================================
import PropertyAnalysis from "@/views/propertyAnalysis/index";
import PropertyAnalysisAdd from "@/views/propertyAnalysis/add/index";
import PropertyAnalysisEdit from "@/views/propertyAnalysis/edit/_edit";

import AgentPerformance from "@/views/agentPerformance/index";
import AgentPerformanceAdd from "@/views/agentPerformance/add/index";
import AgentPerformanceEdit from "@/views/agentPerformance/edit/_edit";

import FinancialAnalysis from "@/views/financialAnalysis/index";
import FinancialAnalysisAdd from "@/views/financialAnalysis/add/index";
import FinancialAnalysisEdit from "@/views/financialAnalysis/edit/_edit";

import BuyerAnalysis from "@/views/buyerAnalysis/index";
import BuyerAnalysisAdd from "@/views/buyerAnalysis/add/index";
import BuyerAnalysisEdit from "@/views/buyerAnalysis/edit/_edit";

// =================================== Misc ====================================

// import Appointment from "@/views/appointment/index";
// import AppointmentAdd from "@/views/appointment/add/index";
// import AppointmentEdit from "@/views/appointment/edit/_edit";

// =============================================================================

Vue.use(VueRouter);

const auth = true;

const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/index"),
        meta: {
            auth: !auth
        }
    },
    {
        path: "/",
        name: "Entry",
        component: () => import("../views/entry"),
        redirect: "/manage-property",
        meta: {
            auth: auth
        },
        children: [
            // ============ Manage Custom Categories ============
            {
                path: "/manage-property-custom-categories",
                name: "ManagePropertyCustomCategories",
                component: () =>
                    import("@/views/managePropertyCustomCategories/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property-custom-categories/add",
                name: "ManagePropertyCustomCategoriesAdd",
                component: () =>
                    import("@/views/managePropertyCustomCategories/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property-custom-categories/edit/:id",
                name: "ManagePropertyCustomCategoriesEdit",
                component: () =>
                    import("@/views/managePropertyCustomCategories/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Property Tags ============
            {
                path: "/manage-property-tags",
                name: "ManagePropertyTags",
                component: () => import("@/views/managePropertyTags/index"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Property Types ============
            {
                path: "/manage-property-types",
                name: "ManagePropertyTypes",
                component: () => import("@/views/managePropertyType/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property-types/add",
                name: "ManagePropertyTypesAdd",
                component: () => import("@/views/managePropertyType/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property-types/edit/:id",
                name: "ManagePropertyTypesEdit",
                component: () =>
                    import("@/views/managePropertyType/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Lot Type ============
            {
                path: "/manage-lot-types",
                name: "ManageLotTypes",
                component: () => import("@/views/manageLotType/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-lot-types/add",
                name: "ManageLotTypesAdd",
                component: () => import("@/views/manageLotType/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-lot-types/edit/:id",
                name: "ManageLotTypesEdit",
                component: () => import("@/views/manageLotType/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Facilities & Amenities ============
            {
                path: "/manage-amenities",
                name: "ManageAmenities",
                component: () => import("@/views/manageAmenities/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-amenities/add",
                name: "ManageAmenitiesAdd",
                component: () => import("@/views/manageAmenities/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-amenities/edit/:id",
                name: "ManageAmenitiesEdit",
                component: () => import("@/views/manageAmenities/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-facilities",
                name: "ManageFacilities",
                component: () => import("@/views/manageFacilities/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-facilities/add",
                name: "ManageFacilitiesAdd",
                component: () => import("@/views/manageFacilities/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-facilities/edit/:id",
                name: "ManageFacilitiesEdit",
                component: () => import("@/views/manageFacilities/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Address ============
            {
                path: "/manage-countries",
                name: "ManageCountries",
                component: () => import("@/views/manageCountries/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-countries/add",
                name: "ManageCountriesAdd",
                component: () => import("@/views/manageCountries/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-countries/edit/:id",
                name: "ManageCountriesEdit",
                component: () => import("@/views/manageCountries/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-states",
                name: "ManageStates",
                component: () => import("@/views/manageStates/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-states/add",
                name: "ManageStatesAdd",
                component: () => import("@/views/manageStates/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-states/edit/:id",
                name: "ManageStatesEdit",
                component: () => import("@/views/manageStates/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-cities",
                name: "ManageCities",
                component: () => import("@/views/manageCities/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-cities/add",
                name: "ManageCitiesAdd",
                component: () => import("@/views/manageCities/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-cities/edit/:id",
                name: "ManageCitiesEdit",
                component: () => import("@/views/manageCities/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-areas",
                name: "ManageAreas",
                component: () => import("@/views/manageAreas/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-areas/add",
                name: "ManageAreasAdd",
                component: () => import("@/views/manageAreas/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-areas/edit/:id",
                name: "ManageAreasEdit",
                component: () => import("@/views/manageAreas/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-buildings",
                name: "ManageBuildings",
                component: () => import("@/views/manageBuildings/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-buildings/add",
                name: "ManageBuildingsAdd",
                component: () => import("@/views/manageBuildings/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-buildings/edit/:id",
                name: "ManageBuildingsEdit",
                component: () => import("@/views/manageBuildings/edit/_edit"),
                meta: {
                    auth: auth
                }
            },

            // ============ Manage Buyer ============
            {
                path: "/manage-buyer",
                name: "ManageBuyer",
                component: () => import("@/views/manageBuyer"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-buyer/detail/:id",
                name: "ManageBuyerDetail",
                component: () => import("@/views/manageBuyer/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Agency ============
            {
                path: "/manage-agency",
                name: "ManageAgency",
                component: () => import("@/views/manageAgency/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-agency/add",
                name: "ManageAgencyAdd",
                component: () => import("@/views/manageAgency/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-agency/edit/:id",
                name: "ManageAgencyEdit",
                component: () => import("@/views/manageAgency/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-agency/:id/roles",
                name: "ManageAgencyRole",
                component: () =>
                    import("@/views/manageAgency/roles/_roles.vue"),
                meta: {
                    auth: auth
                }
            },
            // ========= Manage Agency Users =========
            {
                path: "/manage-agency-users",
                name: "ManageAgencyUsers",
                component: () => import("@/views/manageAgencyUsers/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-agency-users/add",
                name: "ManageAgencyUsersAdd",
                component: () => import("@/views/manageAgencyUsers/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-agency-users/edit/:id",
                name: "ManageAgencyUsersEdit",
                component: () => import("@/views/manageAgencyUsers/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Branch ============
            {
                path: "/manage-branch",
                name: "ManageBranch",
                component: () => import("@/views/manageBranch/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-branch/add",
                name: "ManageBranchAdd",
                component: () => import("@/views/manageBranch/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-branch/edit/:id",
                name: "ManageBranchEdit",
                component: () => import("@/views/manageBranch/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Property ============
            {
                path: "/manage-property",
                name: "ManageProperty",
                component: () => import("@/views/manageProperty/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-property/:id(\\d+)",
                name: "ManagePropertyDetail",
                component: () =>
                    import("@/views/manageProperty/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            // {
            //   path: "/manage-property/add",
            //   name: "ManagePropertyAdd",
            //   component: ManagePropertyAdd,
            //   meta: {
            //     auth: auth
            //   }
            // },
            // {
            //   path: "/manage-property/edit/:id",
            //   name: "ManagePropertyEdit",
            //   component: ManagePropertyEdit,
            //   meta: {
            //     auth: auth
            //   }
            // },
            // ============ Manage Account ============
            {
                path: "/manage-account",
                name: "ManageAccount",
                component: () => import("@/views/manageAccount/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Appointment ============
            // {
            //   path: "/appointment",
            //   name: "Appointment",
            //   component: Appointment,
            //   meta: {
            //     auth: auth
            //   }
            // },
            // {
            //   path: "/appointment/add",
            //   name: "AppointmentAdd",
            //   component: AppointmentAdd,
            //   meta: {
            //     auth: auth
            //   }
            // },
            // {
            //   path: "/appointment/edit/:id",
            //   name: "AppointmentEdit",
            //   component: AppointmentEdit,
            //   meta: {
            //     auth: auth
            //   }
            // },

            // Manage App Banner
            {
                path: "/app-banners/manage",
                name: "ManageAppBanners",
                component: () => import("@/views/manageAppBanners"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/app-popups/manage",
                name: "ManageAppPopups",
                component: () => import("@/views/manageAppPopups"),
                meta: {
                    auth: auth
                }
            },

            // =================================================================
            //                              Project
            // =================================================================

            // ============ Manage Developer ============
            {
                path: "/manage-developers",
                name: "ManageDevelopers",
                component: () => import("@/views/manageDevelopers/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-developers/add",
                name: "ManageDevelopersAdd",
                component: () => import("@/views/manageDevelopers/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-developers/edit/:id",
                name: "ManageDevelopersEdit",
                component: () => import("@/views/manageDevelopers/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-developers/:id/bank-account",
                name: "ManageDeveloperBankAccounts",
                component: () => import("@/views/manageDeveloperBankAccounts"),
                meta: {
                    auth: auth
                }
            },

            // ============ Manage Developer Users ============
            {
                path: "/manage-developers/:developerId/users",
                name: "ManageDeveloperUsers",
                component: () => import("@/views/manageDeveloperUsers"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-developers/:developerId/users/add",
                name: "ManageDeveloperUsersAdd",
                component: () => import("@/views/manageDeveloperUsers/add"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-developers/:developerId/users/:userId/edit",
                name: "ManageDeveloperUsersEdit",
                component: () =>
                    import("@/views/manageDeveloperUsers/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            // ============ Manage Bankers ============
            {
                path: "/manage-bankers",
                name: "ManageBankers",
                component: () => import("@/views/manageBankers/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-bankers/add",
                name: "ManageBankersAdd",
                component: () => import("@/views/manageBankers/add/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-bankers/edit/:id",
                name: "ManageBankersEdit",
                component: () => import("@/views/manageBankers/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-bankers/detail/:id",
                name: "ManageBankersDetail",
                component: () => import("@/views/manageBankers/detail/_detail"),
                meta: {
                    auth: auth
                }
            },

            // Form
            {
                path: "/loan-application-form",
                name: "LoanApplicationFormBuilder",
                component: () => import("@/views/loanApplicationFormBuilder"),
                meta: {
                    auth: auth
                }
            },

            // ============ Manage Lawyers ============
            {
                path: "/manage-lawyers",
                name: "ManageLawyers",
                component: () => import("@/views/manageLawyers/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-lawyers/add",
                name: "ManageLawyersAdd",
                component: () => import("@/views/manageLawyers/add"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-lawyers/edit/:id",
                name: "ManageLawyersEdit",
                component: () => import("@/views/manageLawyers/edit/_edit"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-lawyers/detail/:id",
                name: "ManageLawyersDetail",
                component: () => import("@/views/manageLawyers/detail/_detail"),
                meta: {
                    auth: auth
                }
            },

            // ============ Manage Designers ============
            {
                path: "/manage-designers",
                name: "ManageDesigners",
                component: () => import("@/views/manageDesigners/index"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-designers/add",
                name: "ManageDesignersAdd",
                component: () => import("@/views/manageDesigners/add"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/manage-designers/edit/:id",
                name: "ManageDesignersEdit",
                component: () => import("@/views/manageDesigners/edit/_edit"),
                meta: {
                    auth: auth
                }
            },

            // =================================================================
            //                              Analysis
            // =================================================================

            // ============ Property Analysis ============
            {
                path: "/property-analysis",
                name: "PropertyAnalysis",
                component: PropertyAnalysis,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/property-analysis/add",
                name: "PropertyAnalysisAdd",
                component: PropertyAnalysisAdd,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/property-analysis/edit/:id",
                name: "PropertyAnalysisEdit",
                component: PropertyAnalysisEdit,
                meta: {
                    auth: auth
                }
            },
            // ============ Agent Performance ============
            {
                path: "/agent-performance",
                name: "AgentPerformance",
                component: AgentPerformance,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/agent-performance/add",
                name: "AgentPerformanceAdd",
                component: AgentPerformanceAdd,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/agent-performance/edit/:id",
                name: "AgentPerformanceEdit",
                component: AgentPerformanceEdit,
                meta: {
                    auth: auth
                }
            },
            // ============ Financial Analysis ============
            {
                path: "/financial-analysis",
                name: "FinancialAnalysis",
                component: FinancialAnalysis,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/financial-analysis/add",
                name: "FinancialAnalysisAdd",
                component: FinancialAnalysisAdd,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/financial-analysis/edit/:id",
                name: "FinancialAnalysisEdit",
                component: FinancialAnalysisEdit,
                meta: {
                    auth: auth
                }
            },
            // ============ Buyer Analysis ============
            {
                path: "/buyer-analysis",
                name: "BuyerAnalysis",
                component: BuyerAnalysis,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/buyer-analysis/add",
                name: "BuyerAnalysisAdd",
                component: BuyerAnalysisAdd,
                meta: {
                    auth: auth
                }
            },
            {
                path: "/buyer-analysis/edit/:id",
                name: "BuyerAnalysisEdit",
                component: BuyerAnalysisEdit,
                meta: {
                    auth: auth
                }
            }
        ]
    },
    {
        path: "/*",
        name: "NotFound",
        component: () => import("@/views/404/NotFound"),
        meta: {
            auth: undefined
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

Vue.router = router;

import permissionMiddleware from "./middleware/permission";
import store from "../store";

router.beforeEach((to, from, next) => {
    store.commit("setIsLoading", false, true);

    if (permissionMiddleware({ to })) {
        next();
        return;
    } else {
        next("/");
        return;
    }
});

export default router;
