import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import login from "./login/index";

import ManageCountries from "@/store/manageCountries/index";
import ManageStates from "@/store/manageStates/index";
import ManageCities from "@/store/manageCities/index";
import ManageAreas from "@/store/manageAreas/index";
import ManageBuildings from "@/store/manageBuildings/index";

import ManageBuyer from "@/store/manageBuyer/index";
import ManageAgency from "@/store/manageAgency/index";
import ManageAgencyUserRole from "@/store/manageAgencyUserRole/index";
import ManageBranch from "@/store/manageBranch/index";

import ManageProperty from "@/store/manageProperty/index";
import ManagePropertyCustomCategories from "@/store/managePropertyCustomCategories/index";
import ManagePropertyTags from "@/store/managePropertyTags/index";
import ManagePropertyTypes from "@/store/managePropertyType/index";
import ManageLotTypes from "@/store/manageLotType/index";
import ManageAmenities from "@/store/manageAmenities/index";
import ManageFacilities from "@/store/manageFacilities/index";
import Appointment from "@/store/appointment/index";

import ManageDevelopers from "@/store/manageDevelopers/index";

import PropertyAnalysis from "@/store/propertyAnalysis/index";
import AgentPerformance from "@/store/agentPerformance/index";
import FinancialAnalysis from "@/store/financialAnalysis/index";
import BuyerAnalysis from "@/store/buyerAnalysis/index";

import ManageAccount from "@/store/manageAccount/index";
import Sidebar from "@/store/misc/sidebar";
import Upload from "@/store/misc/upload";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loaderQueue: []
    },
    mutations: {
        setIsLoading(state, isLoading, all = false) {
            if (all) {
                if (isLoading) {
                    state.loaderQueue = [];
                    state.loaderQueue.push(1);
                } else {
                    state.loaderQueue = [];
                }
            } else {
                if (isLoading) {
                    state.loaderQueue.push(state.loaderQueue.length + 1);
                } else {
                    state.loaderQueue.pop();
                }
            }
        }
    },
    getters: {
        getIsLoading(state) {
            if (state.loaderQueue.length > 0) {
                return true;
            }
            return false;
        }
    },
    actions: {
        async uploadImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);

                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Image");
                throw error;
            }
        },
        async uploadWatermarkImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);
                form.append("watermark", true);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);
                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Watermark Image");
                throw error;
            }
        }
    },
    modules: {
        login: login,

        manageCountries: ManageCountries,
        manageStates: ManageStates,
        manageCities: ManageCities,
        manageAreas: ManageAreas,
        manageBuildings: ManageBuildings,

        manageBuyer: ManageBuyer,
        manageAgency: ManageAgency,
        manageAgencyUserRole: ManageAgencyUserRole,
        manageBranch: ManageBranch,

        manageProperty: ManageProperty,
        managePropertyCustomCategories: ManagePropertyCustomCategories,
        managePropertyTags: ManagePropertyTags,
        managePropertyTypes: ManagePropertyTypes,
        manageLotTypes: ManageLotTypes,
        manageAmenities: ManageAmenities,
        manageFacilities: ManageFacilities,
        appointment: Appointment,

        manageDevelopers: ManageDevelopers,

        propertyAnalysis: PropertyAnalysis,
        agentPerformance: AgentPerformance,
        financialAnalysis: FinancialAnalysis,
        buyerAnalysis: BuyerAnalysis,

        manageAccount: ManageAccount,

        sidebar: Sidebar,
        upload: Upload
    }
});
