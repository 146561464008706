import axios from "axios";

export default {
	namespaced: true,
	state: () => {
		return {};
	},
	getters: {},
	mutations: {},
	actions: {
		async updatePassword({ commit }, content) {
			commit("setIsLoading", true, { root: true });

			try {
				const request = await axios.put(
					`${this._vm.$adminAPI}/user-profile/password`,
					content
				);

				commit("setIsLoading", false, { root: true });
				return request.data;
			} catch (error) {
				commit("setIsLoading", false, { root: true });
				throw error;
			}
		},
		// Permission
		checkPermission({ commit, state }, permission) {
			let permissions = JSON.parse(localStorage.getItem("permission"));
			if (this._vm._.get(permissions, permission)) {
				return true;
			} else {
				return false;
			}
		},
		async getAccountPermission({ commit, state }) {
			try {
				let response = await axios.get(
					`${this._vm.$adminAPI}/account/permission`
				);
				localStorage.setItem("permission", JSON.stringify(response.data));
				return;
			} catch (error) {
				throw error;
			}
		}
	}
};
