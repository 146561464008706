import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    // ======================== Main CRUD  ========================
    async getAllProperties({ commit }, payload) {
      try {
        let include;

        let response = await axios.get(
          `${this._vm.$adminAPI}/properties`,
          {
            params: {
              ...payload,
              include: include
            }
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getProperty({ commit }, { id }) {
      let include =
        "owner,propertyFacilities,propertyAmenities,propertyTags,propertyCustomCategories,offerToPurchase,propertyAuction";

      try {
        const response = await axios.get(
          `${this._vm.$adminAPI}/properties/${id}?include=${include}`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createProperty({ commit }, { payload }) {
      try {
        const request = await axios.post(
          `${this._vm.$adminAPI}/properties`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async updateProperty({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$adminAPI}/properties/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteProperty({ commit }, { id }) {
      try {
        let response = await axios.delete(
          `${this._vm.$adminAPI}/properties/${id}`
        );
        return response;
      } catch (error) {
        return false;
      }
    },

    async updateStatus({ commit }, { id, status }) {
      try {
        let response = await axios.put(
          `${this._vm.$adminAPI}/properties/${id}/status`,
          status
        );
        return response;
      } catch (error) {
        throw error;
      }
    },

    async updatePrice({ commit }, { id, payload }) {
      try {
        let response = await axios.put(
          `${this._vm.$adminAPI}/properties/${id}/price`,
          payload
        );
        return response;
      } catch (error) {
        throw error;
      }
    },

    // ======================== Misc APIs  ========================
    async getPropertyTypes({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/property-types?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getLotTypes({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/lot-types?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getCountries({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/countries?limit=200`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getStates({ commit }, { countryId = null }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/states?limit=200`,
          {
            params: {
              "country:id": countryId
            }
          }
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },

    async getCities({ commit }, { stateId = null }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/cities?limit=200`,
          {
            params: {
              "state:id": stateId
            }
          }
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getAreas({ commit }, { cityId = null }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/areas?limit=200`,
          {
            params: {
              "city:id": cityId
            }
          }
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getBuildings({ commit }, { areaId = null }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/buildings?limit=200`,
          {
            params: {
              "area:id": areaId
            }
          }
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getStreets({ commit }, { areaId = null, name = null }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/streets?limit=10`,
          {
            params: {
              "area:id": areaId,
              "name[partial]": name
            }
          }
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getPropertyFacilities({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/property-facilities?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getPropertyAmenities({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/property-amenities?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getPropertyCustomCategories({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/property-custom-categories?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async getPropertyTags({ commit }) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/property-tags?limit=100`
        );
        return response.data.data;
      } catch (error) {
        return false;
      }
    }
  }
};
