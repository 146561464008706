import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllBranches({ commit }, payload) {
      try {
        let include = "agency";

        let response = await axios.get(
          `${this._vm.$adminAPI}/agency-branches`,
          {
            params: {
              ...payload,
              include: include,
            }
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getBranch({ commit }, { id }) {
      let include = "agency";

      try {
        let response = await axios.get(
          `${this._vm.$adminAPI}/agency-branches/${id}?include=${include}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createBranch({ commit }, payload) {
      try {
        const response = await axios.post(
          `${this._vm.$adminAPI}/agency-branches`,
          payload
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateBranch({ commit }, { id, payload }) {
      try {
        const request = await axios.put(
          `${this._vm.$adminAPI}/agency-branches/${id}`,
          payload
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async deleteBranch({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.delete(`/api/manage-branch/${content}`);

        commit("setIsLoading", false, { root: true });
        return true;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        return false;
      }
    }
  }
};
