import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllManageBuyer({ commit }, payload) {
      try {
        let include;

        let response = await axios.get(
          `${this._vm.$adminAPI}/app-users`,
          {
            params: {
              ...payload,
              include: include
            }
          }
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getManageBuyer({ commit }, id) {
      try {
        let include = "country,state,city";

        let response = await axios.get(
          `${this._vm.$commonAPI}/app-users/${id}` +
          `?include=${include}`,
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateBuyersStatus({ commit }, content) {
      commit("setIsLoading", true, { root: true, });

      try {
        const request = await axios.put(`${this._vm.$adminAPI}/app-users/${content.id}/update-active`, content.data);

        commit("setIsLoading", false, { root: true, });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true, });
        throw error;
      }
    },
    /* async deleteManageBuyers({ commit }, id) {
      try {
        const request = await axios.delete(`${this._vm.$adminAPI}/app-users/${id}`);

        return request;
      } catch (error) {
        throw error;
      }
    }, */
  }
};
