<template>
  <div class="financial-analysis animatedBox">
    <div class="container fluid">
      <div class="card p-1">
        <fd-table
          :componentTitle="'FinancialAnalysiss'"
          :routerButton="routerButton"
          :tableButton="actionButton"
          :indexed="true"
          :plugin="{
            search: [1, 2]
          }"
          :searchBarTitle="'Search...'"
          :getFunction="getAllMilestones"
        ></fd-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "financial-analysis",
  data() {
    return {
      routerButton: [
        {
          title: "Add FinancialAnalysis",
          class: "add",
          routerLink: "/financial-analysis/add"
        }
      ],
      actionButton: [
        {
          title: "Edit",
          class: "edit",
          routerLink: "/financial-analysis/edit"
        },
        {
          title: "Delete",
          class: "delete",
          routerLink: null,
          onclick: async (id) => {
            const c = confirm(
              "Are you sure to delete this financial analysis? You cannot undo after this."
            );

            if (c) {
              return this.removeFinancialAnalysis(id);
            }
          },
          getData: true
        }
      ]
    };
  },
  methods: {
    async getAllMilestones(keyword, currentPage, perPage, isLoading) {
      const request = await this.$store.dispatch(
        "financialAnalysis/getAllFinancialAnalysis",
        {
          keyword: keyword,
          currentPage: currentPage,
          perPage: perPage,
          isLoading: isLoading
        }
      );

      return request;
    },
    async removeMilestone(id) {
      const request = await this.$store.dispatch(
        "financialAnalysis/deleteFinancialAnalysis",
        id
      );

      return request;
    }
  }
};
</script>

<style>
.financial-analysis {
}
</style>
