import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllAgentPerformance({ commit }, context) {
      const cloneContext = context != null ? context : {};
      let search = [];

      if (cloneContext.keyword != "" && cloneContext.keyword != null) {
        search.push(["title", "like", `%${cloneContext.keyword}%`]);
      }

      commit("setIsLoading", cloneContext.isLoading == false ? false : true, {
        root: true
      });
      try {
        const request = await axios.post(
          `/api/query?page=${cloneContext.currentPage}`,
          {
            entity: "agent_performances",
            select: ["*"],
            where: search,
            orderBy: { column: "", sort: "" },
            aggregate: { method: "", column: "" },
            pagination: {
              isPaginate: context.perPage != null ? true : false,
              quantity: cloneContext.perPage != null ? cloneContext.perPage : 0
            }
          }
        );

        let response = null;
        if (request.data.data == null) {
          response = request.data;
        } else {
          response = request.data.data;
        }

        let returnBody = [];

        for (let x of response) {
          returnBody.push({
            id: x.id,
            title: "",
            category: ""
          });
        }

        commit("setIsLoading", false, { root: true });
        return {
          head: ["Title", "Category"],
          body: returnBody,
          pagination: {
            per_page: request.data.per_page,
            current_page: request.data.current_page,
            total_pages: request.data.last_page
          }
        };
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async getAgentPerformance({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.post(`/api/query`, {
          entity: "agent_performances",
          select: ["*"],
          where: [["id", content.id]],
          orderBy: { column: "", sort: "" },
          aggregate: { method: "", column: "" },
          pagination: {
            isPaginate: false,
            quantity: 0
          }
        });

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async createAgentPerformance({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.post(`/api/agent-performance`, content);

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async updateAgentPerformance({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.put(
          `/api/agent-performance/${content.id}`,
          content.data
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async deleteAgentPerformance({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.delete(`/api/agent-performance/${content}`);

        commit("setIsLoading", false, { root: true });
        return true;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        return false;
      }
    }
  }
};
