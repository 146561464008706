import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllAreas({ commit }, payload) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/areas`,
          {
            params: {
              ...payload
            }
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getArea({ commit }, id) {
      try {
        let include = "country,state,city";

        let response = await axios.get(
          `${this._vm.$commonAPI}/areas/${id}` + `?include=${include}`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createAreas({ commit }, content) {
      try {
        const request = await axios.post(
          `${this._vm.$adminAPI}/areas`,
          content
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async updateAreas({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.put(
          `${this._vm.$adminAPI}/areas/${content.id}`,
          content.data
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async deleteAreas({ commit }, id) {
      try {
        const request = await axios.delete(`${this._vm.$adminAPI}/areas/${id}`);

        return request;
      } catch (error) {
        throw error;
      }
    }
  }
};
