import axios from "axios";

export default {
  namespaced: true,
  state: () => {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async getAllCities({ commit }, payload) {
      try {
        let response = await axios.get(
          `${this._vm.$commonAPI}/cities`,
          {
            params: {
              ...payload
            }
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getCity({ commit }, id) {
      try {
        let include = "country,state";

        let response = await axios.get(
          `${this._vm.$commonAPI}/cities/${id}` + `?include=${include}`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async createCities({ commit }, content) {
      try {
        const request = await axios.post(
          `${this._vm.$adminAPI}/cities`,
          content
        );

        return request.data;
      } catch (error) {
        throw error;
      }
    },
    async updateCities({ commit }, content) {
      commit("setIsLoading", true, { root: true });

      try {
        const request = await axios.put(
          `${this._vm.$adminAPI}/cities/${content.id}`,
          content.data
        );

        commit("setIsLoading", false, { root: true });
        return request.data;
      } catch (error) {
        commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async deleteCities({ commit }, id) {
      try {
        const request = await axios.delete(
          `${this._vm.$adminAPI}/cities/${id}`
        );

        return request;
      } catch (error) {
        throw error;
      }
    }
  }
};
